import React from "react"
import Header from "@/widgets/Header/Header"
import background from "@/shared/assets/images/BG.webp"
import cls from "./policylegacy.module.scss"
import Footer from "@/widgets/Footer/Footer"

export const PolicyLeagcy = () => {
    return (
        <div className={cls.PolicyLegacy}>
            <div className={cls.container}>
                <Header />
                <h1 className={cls.title}>Policy Legacy</h1>
                <div className={cls.textContainer}>
                    <p>
                        <strong>
                            Version 1.0, last updated:
                            August 10, 2024
                        </strong>
                    </p>
                    <p>
                        We take your privacy seriously.
                        Softner Solutions, s.r.o. (“we”, or
                        “our”, or “us”) has created this
                        Privacy Policy to explain how we
                        collect, store, use, and protect
                        your information. This Privacy
                        Policy explains our information
                        handling practices when you use our
                        communication services, interact
                        with our website, or interact with
                        us directly (collectively the
                        “Services”).
                    </p>
                    <p>
                        This Privacy Policy is incorporated
                        into, and considered a part of, the
                        Terms of Service, and any terms not
                        defined herein have the meanings
                        ascribed to them in the Terms of
                        Service. This Privacy Policy and the
                        Terms of Service govern the
                        collection of information and use of
                        the Services offered by Softner
                        Solutions, s.r.o., and by using the
                        Services you are giving Softner
                        Solutions, s.r.o. permission to use
                        and store such information
                        consistent with this Privacy Policy
                        and the Terms of Service.
                    </p>

                    <h2>1. Acceptance of Policy</h2>
                    <p>
                        By accessing or using the Services,
                        you do hereby warrant and represent
                        that you have read, understand, and
                        agree to all terms and conditions
                        stated herein, and our Terms of
                        Service, currently available at{" "}
                        <a style={{color: 'blue', textDecoration: 'underline'}} href='https://dataholder.io/termsofservice'>
                            https://dataholder.io/termsofservice
                        </a>{" "}
                        (the “Terms of Service”). If you do
                        not agree with or you are not
                        comfortable with any aspect of this
                        Policy, you should immediately
                        discontinue access or use of our
                        Services.
                    </p>

                    <h2>2. Notification of Changes</h2>
                    <p>
                        Softner Solutions, s.r.o.’s Privacy
                        Policy is periodically reviewed and
                        might change as Softner Solutions,
                        s.r.o. updates and expands the
                        Services. Softner Solutions, s.r.o.
                        will endeavor to notify you directly
                        of any material changes, and will
                        post a notice of any non-material
                        changes on our website. Once the new
                        Policy is posted, the Policy will
                        become effective after your
                        continued use of the Services.
                        Softner Solutions, s.r.o. encourages
                        you to review this Privacy Policy
                        periodically.
                    </p>

                    <h2>3. Our Relationship to You</h2>
                    <p>
                        It is important that you identify
                        which relationship(s) you have with
                        Softner Solutions, s.r.o. to
                        understand Softner Solutions,
                        s.r.o.’s data protection obligations
                        and your rights to your Personal
                        Information under this Privacy
                        Policy. Softner Solutions, s.r.o.
                        has the following relationships:
                    </p>
                    <ul>
                        <li>
                            <strong>A “User”</strong> is an
                            individual providing Personal
                            Information to us via our
                            website or other services,
                            products, or platforms, such as
                            by signing up for our newsletter
                            or making an account and posting
                            messages. Here, Softner
                            Solutions, s.r.o. is a data
                            controller.
                        </li>
                        <li>
                            <strong>A “Customer”</strong> is
                            a specific type of User that has
                            engaged us to act as an agent (a
                            “data processor”) by obtaining
                            our Services. The Customer is
                            the data controller, and Softner
                            Solutions, s.r.o. is their data
                            processor.
                        </li>
                        <li>
                            <strong>
                                A “Customer End User”
                            </strong>{" "}
                            is an individual that provides
                            their Personal Information to
                            our Customers. We do not control
                            the purposes or the means by
                            which their Personal Information
                            is collected, and we are not in
                            a direct relationship with
                            Customer End Users.
                        </li>
                    </ul>
                    <p>
                        Hereinafter we may refer to
                        Customers and Users collectively as
                        “you.”
                    </p>

                    <h2>4. Collection</h2>
                    <p>
                        During your use of the Services,
                        Softner Solutions, s.r.o. or
                        third-party vendors contracted by us
                        will collect, use, disclose, store,
                        and process data that identifies you
                        or may make you identifiable
                        (collectively your “Personal
                        Information”).
                    </p>
                    <p>
                        When you use our Services, we
                        generally collect the following
                        Personal Information about you:
                    </p>
                    <ul>
                        <li>
                            Your account registration data
                            you provided, including name,
                            email, and/or avatar;
                        </li>
                        <li>
                            In-Chat information you
                            provided, such as your name,
                            email address, file attachments,
                            and/or phone number;
                        </li>
                        <li>
                            Business information like your
                            company name, company size, and
                            business type;
                        </li>
                        <li>
                            Any other information you
                            provide to us, such as, but not
                            limited to, when you add a
                            coworker to join the Services,
                            refer an individual to Softner
                            Solutions, s.r.o., post on our
                            blog, or submit a resume to
                            apply for a job with us;
                        </li>
                        <li>
                            Account information from Add-ons
                            and other third-party services
                            you use in conjunction with our
                            Services;
                        </li>
                        <li>
                            Cookies and similar tracking
                            information (see “Cookies” below
                            for more information);
                        </li>
                        <li>
                            Data about your use of the
                            Services, such as, but not
                            limited to, interactions with
                            the user interface to the
                            Services, the Internet Protocol
                            Address for the computers you
                            use to connect to the Services,
                            the frequency and size of data
                            transfers, and information you
                            use to describe yourself;
                        </li>
                        <li>
                            Data required for operation of
                            the Services, such as, but not
                            limited to, information you
                            submit to, publish with, or
                            transfer through to the
                            Services.
                        </li>
                    </ul>

                    <h2>5. Use & Purpose</h2>
                    <p>
                        We will not use your Personal
                        Information for purposes other than
                        those described in this Policy or
                        disclosed to you. From time to time
                        we may request your permission to
                        allow us to share your Personal
                        Information with third parties. You
                        can choose not to share your
                        information with third parties. When
                        you limit the ways we can use your
                        Personal Information, some or all of
                        the Services may not be available to
                        you.
                    </p>
                    <p>
                        We use your Personal Information for
                        the following purposes:
                    </p>
                    <ul>
                        <li>
                            <strong>
                                To send you Service-related
                                communications:
                            </strong>{" "}
                            We send administrative or
                            account-related information to
                            you to keep you updated about
                            our Services, inform you of
                            relevant security issues or
                            updates, or provide other
                            transaction-related information
                            to you. Without such
                            communications, you may not be
                            aware of important developments
                            relating to your account that
                            may affect how you can use our
                            Services.
                        </li>
                        <li>
                            <strong>
                                For identification,
                                authentication, and
                                security:
                            </strong>{" "}
                            We are committed to providing
                            you with a secure experience on
                            our Services. To do so, we
                            process your Personal
                            Information to enhance security,
                            monitor and verify identity or
                            service access, and combat spam
                            or other malware or security
                            risks. Without processing your
                            Personal Information, we may not
                            be able to ensure the security
                            of our Services.
                        </li>
                        <li>
                            <strong>
                                To enforce terms,
                                agreements, or policies:
                            </strong>{" "}
                            We process your Personal
                            Information to actively monitor,
                            investigate, prevent, and
                            mitigate any alleged or actual
                            prohibited, illicit or illegal
                            activities on our Services or
                            violations of our posted user
                            terms. We may also process your
                            Personal Information to enforce
                            our agreements with third
                            parties and partners, and/or
                            collect fees based on your use
                            of our Services.
                        </li>
                        <li>
                            <strong>
                                To provide customer service:
                            </strong>{" "}
                            When you contact us with
                            questions, feedback, concerns,
                            disputes, or issues, we process
                            your Personal Information so we
                            can respond to you and resolve
                            any issues. If we do not process
                            your Personal Information, we
                            cannot respond to your requests
                            and ensure your continued use of
                            the Services.
                        </li>
                        <li>
                            <strong>
                                To engage in marketing
                                activities:
                            </strong>{" "}
                            We may send you marketing
                            communications based on your
                            communication settings to inform
                            you about relevant products and
                            services; to deliver targeted
                            marketing; to provide you with
                            promotional offers; or to inform
                            you about our events, webinars,
                            or materials, including those of
                            our partners. You can opt-out of
                            our marketing communications at
                            any time.
                        </li>
                        <li>
                            <strong>
                                To enhance your experience
                                on the Services:
                            </strong>{" "}
                            We process your Personal
                            Information to provide you with
                            a personalized experience on our
                            Services, such as allowing you
                            to use our different plugins
                            (integrations) on our Services
                            or by keeping track of your
                            preferences.
                        </li>
                        <li>
                            <strong>
                                To conduct research and
                                development:
                            </strong>{" "}
                            We process your Personal
                            Information to better understand
                            you and the way you use and
                            interact with our Services.
                        </li>
                        <li>
                            <strong>
                                To maintain legal and
                                regulatory compliance:
                            </strong>{" "}
                            We process your Personal
                            Information to comply with our
                            legal obligations under
                            applicable laws and regulations,
                            such as to ensure we are
                            monitoring our products or
                            Services to ensure they are used
                            legally.
                        </li>
                    </ul>

                    <h2>6. Third-party services</h2>
                    <p>
                        We may share or collect your
                        Personal Information in
                        collaboration with our third party
                        service providers (“Partners”),
                        including companies that assist with
                        payment processing, business
                        analytics, data processing, account
                        management, and other services. We
                        instruct these Partners to not use
                        your Personal Information themselves
                        or share it with another party not
                        under a similar contract to provide
                        service to us, and to only use your
                        Personal Information for the
                        specific services we have requested
                        for us or on your behalf.
                    </p>

                    <h2>7. Further disclosure</h2>
                    <p>
                        We may share with third parties
                        non-Personal Information, public
                        information, and anonymized
                        aggregated data pursuant to this
                        Privacy Policy and as permitted by
                        Data Protection Laws. We may also
                        disclose your Personal Information
                        in the following instances:
                    </p>
                    <ul>
                        <li>
                            As required by law such as to
                            comply with a subpoena, or
                            similar legal process;
                        </li>
                        <li>
                            When we believe in good faith
                            that disclosure is necessary to
                            protect our rights, protect your
                            safety or the safety of others,
                            investigate fraud, or respond to
                            a government request;
                        </li>
                        <li>
                            If we are involved in a merger,
                            acquisition, or sale of all or a
                            portion of our assets, you will
                            be notified via email and/or a
                            prominent notice within the
                            application of any change in
                            ownership or uses of your
                            Personal Information, as well as
                            any choices you may have
                            regarding your Personal
                            Information; and
                        </li>
                        <li>
                            To any other third party with
                            your prior consent to do so.
                        </li>
                    </ul>
                    <p>
                        Designated Countries: For purposes
                        of the Applicable Data Protection
                        Laws, we process this Personal
                        Information, as applicable, to
                        satisfy our legal obligations,
                        protect your vital interests or
                        process data in the public interest,
                        for purposes of our legitimate
                        interests, or with your consent.
                    </p>

                    <h2>8. Personnel</h2>
                    <p>
                        We will only disclose Personal
                        Information to our staff as they
                        need to know and we will ensure such
                        personnel protect Personal
                        Information as required in this
                        Privacy Policy. We do not sell your
                        Personal Information to non-agent
                        third parties.
                    </p>
                    <h2>9. Becoming an example customer</h2>
                    <p>
                        If you would like to be an example
                        customer, write a testimonial, or be
                        highlighted in our newsletter or
                        other communications, please let us
                        know. Otherwise, we will not use you
                        as an example or reference customer
                        in our marketing, communications, or
                        promotional material without your
                        prior consent.
                    </p>

                    <h2>10. Choice/Opt-Out</h2>
                    <p>
                        You may opt-out of receiving
                        communications from us and our
                        partners, remove your information
                        from our database, and choose to not
                        receive future communications
                        unrelated to the Services by
                        cancelling your account.
                    </p>
                    <p>
                        When you register for the Services
                        we will include you in marketing
                        correspondence from Softner
                        Solutions, s.r.o., which you can
                        opt-out of at any time by using the
                        ‘unsubscribe’ option in the email.
                        If you use the Services, we may
                        occasionally contact you with
                        information about special events,
                        sales, activities, promotions,
                        contests, submission opportunities
                        and programs. You always have the
                        option to ask Softner Solutions,
                        s.r.o. not to contact you with this
                        information again.
                    </p>

                    <h2>11. Cookies</h2>
                    <p>
                        When you visit websites on the
                        Internet, your browser may
                        automatically transmit information
                        to the websites you visit throughout
                        every visit. Like many websites, we
                        may use “cookies” to collect
                        information. A cookie is a small
                        data file that we transfer to your
                        computer’s hard disk for
                        record-keeping purposes.
                    </p>

                    <p>
                        Unlike persistent cookies, session
                        cookies are deleted from your
                        computer when you log off from the
                        Services and then close your
                        browser. Third party tools in the
                        Services may also place or read
                        cookies on your browser and we may
                        use Flash cookies (or local shared
                        objects) to store your preferences
                        or use on the Services to
                        personalize your visit. We do not
                        control how third-party cookies are
                        used. You should check the relevant
                        third-party’s website for more
                        information about how they use
                        cookies. Both first party and
                        third-party cookies can serve a
                        number of different functions, such
                        as analytics, marketing and
                        advertising.
                    </p>

                    <p>
                        With your consent, we or our service
                        providers may also use “pixel tags,”
                        “web beacons,” “clear GIFs,” or
                        similar means in connection with the
                        Services and HTML-formatted email
                        messages to, among other things,
                        track the actions of Users and email
                        recipients, to determine the success
                        of marketing campaigns and to
                        compile aggregate statistics about
                        Services usage and response rates.
                        We may also engage one or more third
                        party service providers to serve
                        online advertisements on our behalf.
                        Our service providers may use a
                        “pixel tag” to collect information
                        about your visits to the Services
                        and to other websites, and they may
                        use that information to target
                        advertisements for goods and
                        services. We will not share this
                        information with third parties in a
                        manner that personally identifies
                        you.
                    </p>

                    <p>
                        Softner Solutions, s.r.o. embedded
                        chat (and specifically the domains
                        dataholder.io) does not collect,
                        retain, or share data regarding a
                        particular Customer End User’s
                        activity across multiple websites or
                        applications that are not owned by
                        Softner Solutions, s.r.o.. Softner
                        Solutions, s.r.o. does assign each
                        user a unique user ID within the
                        scope of an individual website, but
                        does not collect or retain IP or any
                        information except information users
                        volunteer (for example, an email)
                        which would allow Softner Solutions,
                        s.r.o. to identify the same
                        particular user on more than one
                        website. For the purposes of abuse
                        and fraud prevention, Softner
                        Solutions, s.r.o. may use Customer
                        End User IP during a chat session to
                        block fraudulent IPs, trolls, and
                        prevent other abuse.
                    </p>
                    <p>
                        We may use the following types of
                        cookies and tracking technologies:
                    </p>
                    <ul>
                        <li>
                            <strong>
                                Strictly Necessary Cookies:
                            </strong>{" "}
                            These cookies are essential
                            because they enable you to move
                            around our Services and use
                            certain features on our
                            Services. For example, strictly
                            necessary cookies allow you to
                            access secure areas. Without
                            these cookies, some Services
                            cannot be provided.
                        </li>
                        {/* <li>
                            <strong>
                                Performance/Analytics
                                Cookies:
                            </strong>{" "}
                            These cookies collect
                            information about your use and
                            interaction with our Services.
                            The types of information
                            collected by such cookies
                            include which pages you go to
                            most often, how much time you
                            spend on that page, or if you
                            get error messages from certain
                            pages.
                        </li> */}
                        <li>
                            <strong>
                                Functionality Cookies:
                            </strong>{" "}
                            These cookies allow us to
                            remember your preferences and
                            choices so that we can tailor
                            our Services to you and your
                            interests. For example, these
                            cookies will remember which
                            country or language you
                            selected, or will use your
                            display name on parts of the
                            Services. If you are logged in
                            to your account, we may
                            associate the cookie information
                            with the Personal Information
                            tied to your account.
                        </li>
                        {/* <li>
                            <strong>
                                Advertising/Marketing
                                Cookies:
                            </strong>{" "}
                            These cookies allow us to
                            deliver relevant marketing and
                            advertisements to you. They
                            collect information about your
                            interactions with our marketing
                            activities (e.g., on the website
                            or via emails) to determine what
                            your interests and preferences
                            are, and how effective such
                            advertising or marketing
                            campaigns are.
                        </li> */}
                    </ul>
                    <p>
                        You can instruct your browser, by
                        changing its options under Settings
                        or Preferences, to stop accepting
                        cookies or to prompt you before
                        accepting a cookie from the websites
                        you visit. If you do not accept
                        cookies, however, you will not be
                        able to use some or all portions or
                        functionalities of the Services.
                    </p>

                    <p>
                        We do not control interest-based
                        advertising cookies on our Services.
                        If you would like more information
                        about such cookies or to opt out of
                        having your cookie information used
                        by companies that are part of the
                        Network Advertising Initiative,
                        please visit{" "}
                        <a href='http://www.networkadvertising.org/choices'>
                            www.networkadvertising.org/choices
                        </a>
                        .
                    </p>

                    <h2>Strictly Necessary</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Provider / Domain</th>
                                <th>Expiration</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>accept_cookies</td>
                                <td>
                                    <a href='https://dataholder.io'>
                                        https://dataholder.io
                                    </a>
                                </td>
                                <td>1 year</td>
                                <td>
                                    This cookie is used by
                                    dataholder.io service to
                                    remember visitor cookie
                                    consent preferences. It
                                    is necessary for
                                    Dataholder.io cookie
                                    banner to work properly.
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <h2>Unclassified</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Provider / Domain</th>
                                <th>Expiration</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>autofillForms</td>
                                <td>
                                    <a href='https://dataholder.io'>
                                        https://dataholder.io
                                    </a>
                                </td>
                                <td>1 year</td>
                                <td>
                                    Cookies for autocomplete
                                    forms
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h2>12. Do not track signals</h2>
                    <p>
                        You may have implemented a “do not
                        track” signal through your browser.
                        As there currently is no fixed
                        standard for do not track signals,
                        we currently do not respond to do
                        not track signals from your web
                        browser.
                    </p>

                    <h2>13. Accountability</h2>
                    <p>
                        We will take all appropriate legal,
                        organizational, and technical
                        measures to protect the Personal
                        Information, keeping in mind the
                        nature of such data. Softner
                        Solutions, s.r.o. shall hold those
                        of its employees with access to
                        Personal Information accountable for
                        violations of this agreement
                        imposing sanctions, which include,
                        where appropriate, the possibility
                        of termination of contracts and
                        employment.
                    </p>

                    <h2>14. Security</h2>
                    <p>
                        The security of your Personal
                        Information is important to us. We
                        maintain, and require our service
                        providers to maintain, appropriate
                        physical, technical and
                        administrative safeguards to protect
                        the security and confidentiality of
                        the Personal Information you provide
                        to us.
                    </p>
                    <p>
                        However, even the best security
                        measures are not foolproof and we
                        cannot guarantee that loss, misuse,
                        unauthorized acquisition, or
                        alteration of your data will not
                        occur. We cannot ensure or warrant
                        the security or confidentiality of
                        the Personal Information you
                        transmit to or receive from us by
                        Internet or wireless connection,
                        including email, phone, or SMS
                        because we have no way of protecting
                        Personal Information that is out of
                        our control.
                    </p>
                    <p>
                        You are also responsible for the
                        security of your Personal
                        Information. You should choose a
                        password of sufficient length and
                        complexity when you use our
                        Services, and keep your password
                        confidential.
                    </p>

                    <h2>15. Retention</h2>
                    <p>
                        We will retain your Personal
                        Information for so long as we have a
                        legal basis to do so.
                    </p>

                    <h2>
                        16. Requests to Access, Edit, or
                        Delete Information
                    </h2>
                    <p>
                        You may edit your account
                        information when logged in to the
                        Services at any time – all you need
                        is your username and password. In
                        addition, you may also ask Softner
                        Solutions, s.r.o. to correct,
                        delete, and/or cease processing your
                        Personal Information from further
                        processing and/or use. It may not be
                        possible for us to delete or cease
                        processing all of the Personal
                        Information we hold about you where
                        we have an ongoing relationship with
                        you or where we have a legal basis
                        to retain such Personal Information.
                    </p>
                </div>
            </div>
            <img
                src={background}
                className={cls.bgWave}
            />
            <Footer className={cls.footer} />
        </div>
    )
}
