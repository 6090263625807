import React, { useEffect, useState } from "react"
import { Logo } from "@/shared/ui/Logo/Logo"
import { ReactComponent as BurgerMenuIcon } from "./assets/burgerIcon.svg"
import { ReactComponent as XMark } from "./assets/xMark.svg"
import Navigation from "@/features/Navigation/Navigation"
import Button from "@/shared/ui/Button/Button"
import { classNames } from "@/shared/lib/utils/classNames"

import cls from "./header.module.scss"
import useResize from "@/shared/lib/hooks/useResize"
import { Select } from "@/features/Select/Select"
import { useTranslation } from "react-i18next"
import { useCookies } from "react-cookie"

const Header = () => {
    const { t, i18n } = useTranslation()
    const [cookie, setCookie] = useCookies()
    const [current, setCurrent] = useState()
    const [open, setOpen] = useState(false)
    const [isScrolling, setScrolling] = useState(true)
    const [lang, setLang] = useState(cookie.lang ?? "en")

    useEffect(() => {
        const changeLang = (lang) => {
            i18n.changeLanguage(lang)
        }
        changeLang(lang)
        setCookie("lang", lang, {
            maxAge: 2592000,
        })
    }, [lang, i18n, setLang])

    useEffect(() => {
        let scrolling
        const handleScrollStop = (event) => {
            setOpen(false)
            window.clearTimeout(scrolling)
            setScrolling(false)
            scrolling = setTimeout(function () {
                if (window.scrollY < 50) {
                    setCurrent("Transparent")
                } else {
                    setCurrent("White")
                }
                setScrolling(true)
            }, 100)
        }

        window.addEventListener("scroll", handleScrollStop)
    }, [
        current,
        setCurrent,
        isScrolling,
        setScrolling,
        setOpen,
    ])

    const windowWidth = useResize()

    const desktopMenu = () => {
        return (
            <header
                className={classNames(cls.Header, [], {
                    [cls.down]: !isScrolling,
                    [cls.back]: current === "White",
                })}>
                <Logo
                    color={
                        current === "White"
                            ? "black"
                            : "white"
                    }
                    className={classNames(cls.Logo, [], {
                        [cls.dark]: current === "White",
                    })}
                />
                <Navigation />

                <div className={cls.buttonContainer}>
                    <Select
                        selected={lang}
                        setValue={setLang}
                        options={["en", "de"]}
                        className={classNames(
                            cls.langSelect,
                            [],
                            {
                                [cls.back]:
                                    current === "White",
                            }
                        )}
                    />
                    <a href='/client'>
                        <Button
                            className={cls.loginButton}
                            size={
                                windowWidth > 1440
                                    ? "medium"
                                    : "small"
                            }
                            link={false}
                            variant='white'>
                            {t("navigation.logIn")}
                        </Button>
                    </a>

                    <a href='/client/register'>
                        <Button
                            className={cls.getButton}
                            size={
                                windowWidth > 1440
                                    ? "medium"
                                    : "small"
                            }
                            link={false}>
                            {t("homeBlock.button")}
                        </Button>
                    </a>
                </div>
            </header>
        )
    }

    const tabletMenu = () => {
        return (
            <header
                className={classNames(
                    cls.tabletHeader,
                    [],
                    {
                        [cls.down]: !isScrolling,
                    }
                )}>
                <div
                    className={classNames(
                        cls.closedMenu,
                        [],
                        {
                            [cls.down]: !isScrolling,
                            [cls.back]: current === "White",
                            [cls.open]: open,
                        }
                    )}>
                    <div>
                        {open ? (
                            <div className={cls.openedMenu}>
                                <XMark
                                    onClick={() =>
                                        setOpen(!open)
                                    }
                                    className={classNames(
                                        cls.icon,
                                        [],
                                        {
                                            [cls.back]:
                                                current ===
                                                "White",
                                        }
                                    )}
                                />
                                <Select
                                    selected={lang}
                                    setValue={setLang}
                                    options={["en", "de"]}
                                    className={
                                        cls.langSelect
                                    }
                                />
                            </div>
                        ) : (
                            <BurgerMenuIcon
                                onClick={() =>
                                    setOpen(!open)
                                }
                                className={classNames(
                                    cls.icon,
                                    [],
                                    {
                                        [cls.back]:
                                            current ===
                                            "White",
                                    }
                                )}
                            />
                        )}
                    </div>
                    {!open && (
                        <div className={cls.LogoContainer}>
                            <Logo
                                className={cls.mobileLogo}
                                color={
                                    current === "White"
                                        ? "black"
                                        : "white"
                                }
                            />
                        </div>
                    )}
                </div>
                <div
                    style={{
                        height: `${
                            window.innerHeight - 80
                        }px`,
                    }}
                    className={classNames(
                        cls.tabletMenu,
                        [],
                        {
                            [cls.close]: !open,
                        }
                    )}>
                    <Navigation
                        mobile
                        className={cls.mobileNavigation}
                        onClick={() => setOpen(false)}
                    />
                    <div className={cls.buttonContainer}>
                        <a href='/client'>
                            <Button
                                className={cls.loginButton}
                                link={false}
                                variant='white'>
                                {t("navigation.logIn")}
                            </Button>
                        </a>
                        <a href='/client/register'>
                            <Button
                                className={cls.getButton}
                                link={false}>
                                {t("homeBlock.button")}
                            </Button>
                        </a>
                    </div>
                </div>
            </header>
        )
    }

    return (
        <>
            {windowWidth <= 1100
                ? tabletMenu()
                : desktopMenu()}
        </>
    )
}

export default Header
