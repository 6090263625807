import React, { useState } from "react"

import { ReactComponent as XMark } from "@/shared/assets/xMark.svg"
import { ReactComponent as VMark } from "@/shared/assets/vMark.svg"
import Button from "@/shared/ui/Button/Button"
import { classNames } from "@/shared/lib/utils/classNames"
import cls from "./pricingCard.module.scss"
import Modal from "@/shared/ui/Modal/Modal"
import PaymentDetail from "@/widgets/Pricing/PaymentDetail/PaymentDetail"
import useResize from "@/shared/lib/hooks/useResize"
import { useTranslation } from "react-i18next"

const PricingCard = ({
    className,
    plan,
    description,
    price,
    prefers,
    recomended,
    link,
}) => {
    const [open, setOpen] = useState(false)
    const { t } = useTranslation()
    const windowWidth = useResize()

    return (
        <>
            <div
                itemScope itemType="http://schema.org/Product"
                className={classNames(
                    cls.PricingCard,
                    [className],
                    {
                        [cls.mobile]: windowWidth < 769,
                        [cls.recommended]:
                            link.toLowerCase() ===
                            "advanced",
                    }
                )}>
                <div className={cls.titleContainer}>
                    {link.toLowerCase() === "advanced" && (
                        <h4 className={cls.recommended}>
                            Recommended
                        </h4>
                    )}
                    <h3 itemProp="name">{plan}</h3>
                    <p itemProp="description">{description}</p>
                    <div itemProp="offers" itemScope itemType="http://schema.org/Offer" className={cls.price}>
                        <sup itemProp="priceCurrency" className={cls.dollar}>$</sup>
                        <span itemProp="price">
                            {price}
                        </span>
                        <span className={cls.period}>
                            /Mo
                        </span>
                    </div>
                </div>
                <ul
                    className={classNames(cls.prefers, [], {
                        [cls.recomended]: recomended,
                    })}>
                    {prefers.map((item) => (
                        <li
                            className={cls.prefersItem}
                            key={item.title}>
                            <p>{item.title}</p>
                            {item.available ? (
                                <VMark
                                    className={cls.vMark}
                                />
                            ) : (
                                <XMark
                                    className={cls.xMark}
                                />
                            )}
                        </li>
                    ))}
                </ul>
                <Button
                    link={false}
                    className={cls.orderButton}
                    onClick={() => setOpen(true)}
                    size='medium'
                    variant={
                        link.toLowerCase() === "advanced"
                            ? "primary"
                            : "outlined"
                    }>
                    {t("pricingBlock.orderPlan")}
                </Button>
            </div>
            <Modal
                isOpen={open}
                setOpen={setOpen}>
                <PaymentDetail
                    setOpen={setOpen}
                    link={link}
                />
            </Modal>
        </>
    )
}

export default PricingCard
