import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: "en",
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: {
                    navigation: {
                        Home: "Home",
                        About: "About",
                        Contact: "Contact",
                        Pricing: "Pricing",
                        UseCases: "Use Cases",
                        Blog: "Blog",
                        Documentation: "Doсumentation",
                        logIn: "Log In",
                    },
                    homeBlock: {
                        title: {
                            part1: "Move Forward",
                            part2: "guided by Data",
                            part3: "tailored for You",
                        },
                        description:
                            "A new way to work with data - store, structure, visualize, analyze, share in one click.",
                        button: "Get Started",
                    },
                    aboutBlock: {
                        about: {
                            title1: "about our products",
                            title2: "Revolutionizing Data Storage And Analysis: Effortless Metrics And Analytics Forever",
                            description:
                                "Our platform leverages advanced technologies for big data processing. We quickly aggregate, systematize, and provide access through a simple API. Visualize data with informative charts for easy analysis. Our tools offer instant access to information, empowering users to make informed decisions.",
                            readMore: "Read More",
                        },
                        features: {
                            title1: "features",
                            content: {
                                powerfulTools: {
                                    title: "Powerful Data Tools at Your Fingertip",
                                    content: `One-Click Storage Integration.\n Our platform provides clear and detailed guides for easy API connection and metric submission.`,
                                },
                                customData: {
                                    title: "Custom Data Queries Made Easy",
                                    content:
                                        "Define parameters and extract any data from the storage based on your specified criteria.",
                                },
                                collaborate: {
                                    title: "Collaborate Seamlessly",
                                    content:
                                        "Divide data across projects, create dashboards, share them with your team, customize access, and personalize visualization options effortlessly.",
                                },
                                stayInformed: {
                                    title: "Stay Informed with Alerts",
                                    content:
                                        "Set up alerts with ease in just one click to receive notifications about metric changes based on specified parameters. Get notified via email, Telegram, or Slack for seamless updates.",
                                },
                            },
                        },
                    },
                    pricingBlock: {
                        title: "Pricing",
                        description1:
                            "Choose a convenient tariff plan or pay as you go, tailored to your needs.",
                        descriptionMobile:
                            "The best work solution, for the best price.",
                        orderPlan: "Order Plan",
                    },
                    plans: {
                        prefers: {
                            personalAccess:
                                "Personal Access",
                            
                            communication:
                                "Team communication",
                            variety: "Variety of Charts",
                            storage: "Unlimited Storage",
                            alarms: "Alarms",
                            logs: "Logs",
                        },
                        basic: {
                            plan: "Basic",
                            projects: "5 Projects",
                            description:
                                "1m records and 100.000 requests",
                        },
                        basicPro: {
                            plan: "Basic Pro",
                            projects: "15 Projects",
                            description:
                                "3m records and 200.000 requests",
                        },
                        advanced: {
                            plan: "Advanced",
                            projects: "Unlimited Projects",
                            description:
                                "Unlim records and 400.000 requests",
                        },
                        advancedPro: {
                            plan: "Advanced Pro",
                            description:
                                "Unlim records and 800.000 requests",
                        },
                        dataPro: {
                            plan: "Data Pro",
                            description:
                                "Unlim records and 2m requests",
                        },
                        personalOffer: {
                            title: "Personal Offer",
                            content:
                                "Try our service, a personal manager will advise you",
                            bookACall: "Book a call",
                        },
                        freePlan: {
                            title: "Free Trial",
                            buttonText: "Your Plan",
                        },
                    },
                    blogBlock: {
                        title: "We Are Boosting Efficiency Through Data Management Excellence",
                        description:
                            "Here’s What Our Partners Think:",
                        posts: {
                            firstPost: {
                                text: "Thanks to clear graphs and diagrams, we can easily present our work results and share data with colleagues and clients. Efficient data visualization on the website allows us to persuasively argue our decisions and marketing campaign strategies. I highly recommend this tool for all marketing professionals—you'll be impressed by its functionality and user-friendliness",
                                name: "Alejandro Garcia",
                                role: "Marketing specialist",
                            },
                            secondPost: {
                                text: "Now, there's no need to spend time searching and storing data on our servers. Very convenient and functional! Lorem ipsum dolor sit amet consectetur adipiscing elit cursus ullamcorper netus facilisis cras ut sed sodales tellus ac",
                                name: "Anders Larsen",
                                role: "Marketing specialist",
                            },
                            thirdPost: {
                                name: "Lars Olsen",
                                text: "Thanks to this website, I find it easy to analyze project results. Convenient dashboards and graphs help quickly identify key trends and make informed decisions. I am very satisfied!",
                                role: "Project manager",
                            },
                            fourthPost: {
                                text: "It's great to have access to old logs for analytics without storing them on our servers. The convenience of the online storage makes data retrieval fast and secure. No more hassle with local storage and searching for specific information. Very convenient and functional!",
                                name: "Erik Johansen",
                                role: "",
                            },
                        },
                    },
                    farewellBlock: {
                        getText: "Get Trial DataHolder",
                        description:
                            "With unlimited access to one project",
                        buttonText: "Get Trial DataHolder",
                    },
                    aboutPage: {
                        mainTitle: "About Our Project",
                        textBlockTitle:
                            "Revolutionizing Data Storage and Analysis: Effortless Metrics and Analytics Forever",
                        textBlockDescription1:
                            "Experience the power of Data Holder, a cutting-edge solution crafted to streamline your data management needs. Whether you're looking to securely store vast amounts of information or seamlessly retrieve and export data in user-friendly formats, Data Holder offers intuitive features tailored to enhance your workflow.",
                        textBlockDescription2:
                            "Visualize complex datasets with interactive charts and graphs, providing clarity and insights at a glance. Customize notifications to stay informed of critical updates and trends, ensuring you never miss a beat in your data-driven decision-making process. With Data Holder, managing and harnessing the potential of your data has never been more efficient or accessible.",
                        listTitle:
                            "Discover why Data Holder is the ideal service for your data needs:",
                        listTitle1:
                            "User-Friendly Interface:",
                        listTitle2:
                            "Comprehensive Functionality:",
                        listTitle3: "Flexible Pricing:",
                        listDescription1:
                            "Navigate effortlessly through our intuitive platform designed for seamless user experience.",
                        listDescription2:
                            "Access all essential tools for robust data management, from storage to advanced analytics, in one integrated solution.",
                        listDescription3:
                            "Enjoy competitive pricing plans tailored to suit your budget and scalability requirements.",
                        listSubDiscription:
                            "Experience simplicity, functionality, and affordability with Data Holder — empowering you to harness the full potential of your data effortlessly.",
                        dataHolderIdeal:
                            "DataHolder ideal for:",
                        dhIdealTitle1: "For Developer",
                        dhIdealTitle2: "For Businessman",
                        dhIdealTitle3: "For Marketing",
                        dhIdealDiscription1:
                            "With its user-friendly API and seamless integration capabilities, Data Holder simplifies the process of storing, retrieving, and analyzing data, allowing developers to focus on innovation rather than infrastructure",
                        dhIdealDiscription2:
                            "With Data Holder, executives can gain a comprehensive view of their business operations through intuitive dashboards and real-time analytics.",
                        dhIdealDiscription3:
                            "With its robust data management capabilities, Data Holder allows marketers to efficiently collect, organize, and analyze customer data from various sources.",
                        ourTeam: "Our Team",
                        ceo: "CEO Founder",
                        cto: "CTO",
                        cpo: "CPO",
                        frontend: "Lead Frontend Developer",
                        rfrontend: "Frontend Developer",
                        backend: "Backend Developer",
                        designer: "Lead Designer",
                        getTrial: "Get Trial",
                        discount:
                            "or grab your 15% discount for Pro version",
                        getDH: "Get DataHolder",
                    },
                },
            },
            de: {
                translation: {
                    navigation: {
                        Home: "Startseite",
                        About: "Über uns",
                        Contact: "Kontakt",
                        Pricing: "Preise",
                        UseCases: "Einsatzfälle",
                        Blog: "Blog",
                        Documentation: "Dokumentation",
                        logIn: "Anmelden",
                    },
                    homeBlock: {
                        title: {
                            part1: "Vorankommen",
                            part2: "geleitet von Daten",
                            part3: "maßgeschneidert für Sie",
                        },
                        description:
                            "Eine neue Art, mit Daten zu arbeiten - speichern, strukturieren, visualisieren, analysieren, teilen mit einem Klick.",
                        button: "Loslegen",
                    },
                    aboutBlock: {
                        about: {
                            title1: "über unsere Produkte",
                            title2: "Revolutionierung der Datenspeicherung und -analyse: Mühelose Metriken und Analysen für immer",
                            description:
                                "Unsere Plattform nutzt fortschrittliche Technologien zur Verarbeitung großer Datenmengen. Wir aggregieren, systematisieren und stellen den Zugriff über eine einfache API schnell zur Verfügung. Visualisieren Sie Daten mit informativen Diagrammen für eine einfache Analyse. Unsere Tools bieten sofortigen Zugang zu Informationen, sodass Benutzer fundierte Entscheidungen treffen können.",
                            readMore: "Mehr erfahren",
                        },
                        features: {
                            title1: "Eigenschaften",
                            content: {
                                powerfulTools: {
                                    title: "Leistungsstarke Datenwerkzeuge zur Hand",
                                    content:
                                        "Ein-Klick-Speicherintegration. Unsere Plattform bietet klare und detaillierte Anleitungen für eine einfache API-Verbindung und Metrikübermittlung.",
                                },
                                customData: {
                                    title: "Benutzerdefinierte Datenabfragen leicht gemacht",
                                    content:
                                        "Definieren Sie Parameter und extrahieren Sie beliebige Daten aus dem Speicher basierend auf Ihren festgelegten Kriterien.",
                                },
                                collaborate: {
                                    title: "Nahtlos zusammenarbeiten",
                                    content:
                                        "Teilen Sie Daten in Projekte auf, erstellen Sie Dashboards, teilen Sie diese mit Ihrem Team, passen Sie den Zugriff an und personalisieren Sie Visualisierungsoptionen mühelos.",
                                },
                                stayInformed: {
                                    title: "Bleiben Sie mit Benachrichtigungen informiert",
                                    content:
                                        "Richten Sie Benachrichtigungen mühelos mit nur einem Klick ein, um über Metrikänderungen basierend auf festgelegten Parametern benachrichtigt zu werden. Erhalten Sie Benachrichtigungen per E-Mail, Telegram oder Slack für nahtlose Updates.",
                                },
                            },
                        },
                    },
                    pricingBlock: {
                        title: "Preise",
                        description1:
                            "Wählen Sie einen passenden Tarifplan oder zahlen Sie nach Bedarf, maßgeschneidert für Ihre Bedürfnisse.",
                        descriptionMobile:
                            "Die beste Arbeitslösung zum besten Preis.",
                        orderPlan: "Plan bestellen",
                    },
                    plans: {
                        prefers: {
                            personalAccess:
                                "Persönlicher Zugang",
                            communication:
                                "Teamkommunikation",
                            variety:
                                "Verschiedene Diagramme",
                            storage:
                                "Unbegrenzter Speicher",
                            alarms: "Alarme",
                            logs: "Protokolle",
                        },
                        basic: {
                            plan: "Basis",
                            projects: "5 Projekte",
                            description:
                                "1 Mio. Einträge und 100.000 Anfragen",
                        },
                        basicPro: {
                            plan: "Basis Pro",
                            projects: "15 Projekte",
                            description:
                                "3 Mio. Einträge und 200.000 Anfragen",
                        },
                        advanced: {
                            plan: "Erweitert",
                            projects: "Unbegrenzt Projekte",
                            description:
                                "Unbegrenzte Einträge und 400.000 Anfragen",
                        },
                        advancedPro: {
                            plan: "Erweitert Pro",
                            description:
                                "Unbegrenzte Einträge und 800.000 Anfragen",
                        },
                        dataPro: {
                            plan: "Daten Pro",
                            description:
                                "Unbegrenzte Einträge und 2 Mio. Anfragen",
                        },
                        personalOffer: {
                            title: "Persönliches Angebot",
                            content:
                                "Probieren Sie unseren Service aus, ein persönlicher Manager wird Sie beraten",
                            bookACall: "Termin vereinbaren",
                        },
                        freePlan: {
                            title: "Kostenlose Testversion",
                            buttonText: "Ihr Plan",
                        },
                    },
                    blogBlock: {
                        title: "Wir steigern die Effizienz durch hervorragendes Datenmanagement",
                        description:
                            "Das denken unsere Partner:",
                        posts: {
                            firstPost: {
                                text: "Dank klarer Grafiken und Diagramme können wir unsere Arbeitsergebnisse leicht präsentieren und Daten mit Kollegen und Kunden teilen. Die effiziente Datenvisualisierung auf der Website ermöglicht es uns, unsere Entscheidungen und Marketingstrategien überzeugend darzustellen. Ich empfehle dieses Tool allen Marketingfachleuten sehr – Sie werden von seiner Funktionalität und Benutzerfreundlichkeit beeindruckt sein.",
                                name: "Alejandro Garcia",
                                role: "Marketing-Spezialist",
                            },
                            secondPost: {
                                text: "Jetzt müssen wir keine Zeit mehr mit der Suche und Speicherung von Daten auf unseren Servern verbringen. Sehr praktisch und funktional! Lorem ipsum dolor sit amet consectetur adipiscing elit cursus ullamcorper netus facilisis cras ut sed sodales tellus ac",
                                name: "Anders Larsen",
                                role: "Marketing-Spezialist",
                            },
                            thirdPost: {
                                name: "Lars Olsen",
                                text: "Dank dieser Website fällt es mir leicht, Projektergebnisse zu analysieren. Praktische Dashboards und Grafiken helfen, wichtige Trends schnell zu erkennen und fundierte Entscheidungen zu treffen. Ich bin sehr zufrieden!",
                                role: "Projektmanager",
                            },
                            fourthPost: {
                                text: "Es ist großartig, Zugang zu alten Protokollen für Analysen zu haben, ohne sie auf unseren Servern speichern zu müssen. Die Bequemlichkeit des Online-Speichers macht das Abrufen von Daten schnell und sicher. Kein Ärger mehr mit lokalem Speicher und der Suche nach spezifischen Informationen. Sehr praktisch und funktional!",
                                name: "Erik Johansen",
                                role: "",
                            },
                        },
                    },
                    farewellBlock: {
                        getText:
                            "Testversion DataHolder erhalten",
                        description:
                            "Mit unbegrenztem Zugang zu einem Projekt",
                        buttonText:
                            "Testversion DataHolder erhalten",
                    },
                    aboutPage: {
                        mainTitle: "Über unser Projekt",
                        textBlockTitle:
                            "Revolutionierung der Datenspeicherung und -analyse: Mühelose Metriken und Analysen für immer",
                        textBlockDescription1:
                            "Erleben Sie die Leistungsfähigkeit von Data Holder, einer hochmodernen Lösung, die Ihre Datenmanagementbedürfnisse vereinfacht. Egal, ob Sie große Informationsmengen sicher speichern oder Daten nahtlos in benutzerfreundlichen Formaten abrufen und exportieren möchten, Data Holder bietet intuitive Funktionen, die Ihren Workflow verbessern.",
                        textBlockDescription2:
                            "Visualisieren Sie komplexe Datensätze mit interaktiven Diagrammen und Grafiken, die auf einen Blick Klarheit und Einblicke bieten. Passen Sie Benachrichtigungen an, um über wichtige Updates und Trends informiert zu bleiben, und stellen Sie sicher, dass Sie in Ihrem datenbasierten Entscheidungsprozess nichts verpassen. Mit Data Holder war das Verwalten und Nutzen Ihres Datenpotenzials noch nie so effizient und zugänglich.",
                        listTitle:
                            "Entdecken Sie, warum Data Holder der ideale Service für Ihre Datenanforderungen ist:",
                        listTitle1:
                            "Benutzerfreundliche Oberfläche:",
                        listTitle2:
                            "Umfassende Funktionalität:",
                        listTitle3:
                            "Flexible Preisgestaltung:",
                        listDescription1:
                            "Navigieren Sie mühelos durch unsere intuitive Plattform, die für eine nahtlose Benutzererfahrung entwickelt wurde.",
                        listDescription2:
                            "Greifen Sie auf alle wesentlichen Werkzeuge für ein robustes Datenmanagement zu, von der Speicherung bis hin zur fortschrittlichen Analyse, in einer integrierten Lösung.",
                        listDescription3:
                            "Genießen Sie wettbewerbsfähige Preispläne, die auf Ihr Budget und Ihre Skalierungsanforderungen zugeschnitten sind.",
                        listSubDiscription:
                            "Erleben Sie Einfachheit, Funktionalität und Erschwinglichkeit mit Data Holder – und nutzen Sie das volle Potenzial Ihrer Daten mühelos.",
                        dataHolderIdeal:
                            "DataHolder ideal für:",
                        dhIdealTitle1: "Für Entwickler",
                        dhIdealTitle2: "Für Geschäftsleute",
                        dhIdealTitle3: "Für Marketing",
                        dhIdealDiscription1:
                            "Mit seiner benutzerfreundlichen API und nahtlosen Integrationsmöglichkeiten vereinfacht Data Holder den Prozess der Speicherung, des Abrufs und der Analyse von Daten, sodass Entwickler sich auf Innovation statt auf Infrastruktur konzentrieren können.",
                        dhIdealDiscription2:
                            "Mit Data Holder können Führungskräfte einen umfassenden Überblick über ihre Geschäftsabläufe durch intuitive Dashboards und Echtzeitanalysen gewinnen.",
                        dhIdealDiscription3:
                            "Mit seinen robusten Datenmanagementfähigkeiten ermöglicht Data Holder Marketingspezialisten, Kundendaten aus verschiedenen Quellen effizient zu sammeln, zu organisieren und zu analysieren.",
                        ourTeam: "Unser Team",
                        ceo: "Gründer & CEO",
                        cto: "CTO",
                        cpo: "CPO",
                        frontend:
                            "Lead Frontend-Entwickler",
                        rfrontend: "Frontend-Entwickler",
                        backend: "Backend-Entwickler",
                        designer: "Lead Designer",
                        getTrial: "Testversion erhalten",
                        discount:
                            "oder sichern Sie sich 15% Rabatt auf die Pro-Version",
                        getDH: "DataHolder erhalten",
                    },
                },
            },
        },
    })

export default i18n
